.featuresCard-wrapper {
  width: 80%;
  border-radius: 20px;
  margin-bottom: 20px;
  display: flex;
  padding: 0 15px;
  > .left {
    display: flex;
    align-items: center;
    justify-content: center;
    > img {
      width: 80px;
      height: 80px;
    }
  }
  > .right {
    color: #fff;
    padding: 30px;
    > h5 {
      font-size: 1.25rem;
      margin: 0.5rem 0;
      font-weight: 700;
    }
  }
}
.featuresCard-wrapper:hover {
  background-color: #151231;
}
