@import "../../variables";
.plans-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 50px 0;
  .plans-container {
    max-width: $appmaxwidth;
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;

    > h4 {
      margin-bottom: 0;
      color: $themecolor;
      font-weight: 500;
      line-height: 1.2;
      font-size: 1.25rem;
    }
    > h2 {
      font-size: calc(1.375rem + 1.5vw);
      margin: 10px;
      font-weight: 500;
      line-height: 1.2;
      text-align: center;
    }
    .tabs-container {
      width: 60%;
      background-color: #151231;
      border-radius: 20px;
      margin: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      > button {
        width: 50%;
        text-align: center;
        font-size: 16px;
        padding: 10px 25px;
        color: #fff;
        border: none;
        outline: 0;
        background-color: #151231;
        cursor: pointer;
        font-weight: 500;
        border-radius: 20px;
      }
      > .selected {
        border-radius: 5px;
        color: #fff;
        border-radius: 20px;
        background: linear-gradient(291.95deg, #876af6 12.39%, #a587f8 83.66%);
      }
    }
    > .content {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}
