@import "../../variables";
.faq-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  .faq-container {
    max-width: $appmaxwidth;
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0;
    padding-bottom: 50px;
    > h5 {
      margin-bottom: 0.5em;
      color: $themecolor;
      font-weight: 500;
      line-height: 1.2;
      font-size: 1.25rem;
    }
    > h2 {
      margin-top: 0;
      margin-bottom: 0.5rem;
      font-weight: 500;
      line-height: 1.2;
      font-size: calc(1.3rem + 0.6vw);
      color: #fff;
    }
    .faqcard-wrapper {
      cursor: pointer;
      color: #fff;
      background-color: #151231;
      border-radius: 7px;
      padding: 25px;
      display: flex;
      justify-content: center;
      text-align: left;
      margin: 20px 0;
      width: 60%;
      > .selector-container {
        width: 10%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: #8ccffb;

        > img {
          width: 12px;
          margin-top: 8px;
          margin-right: 2px;
        }
        .arrow {
          width: 20px;
          color: #fff;
        }
        .arrow:hover {
          color: $themecolor;
        }
        .clicked {
          transform: rotate(180deg);
          color: $themecolor;
        }
      }
      > .faqs {
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        > div {
          display: flex;
          align-items: center;
          > .icon {
            color: $themecolor;
            margin-right: 10px;
            height: 30px;
            width: 25px;
          }
        }
        h6 {
          font-size: 18px;
          margin: 0;
          font-weight: 600;
        }
        h6:hover {
          color: $themecolor;
        }
        > p {
          font-size: 18px;
          margin: 0;
          margin-top: 10px;
          font-weight: 600;
        }
      }
    }
  }
}
