@import "../../variables";
.navbar-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  top: 0px;
  z-index: 500;
  height: 100px;
  color: #fff;
  > .navbar-container {
    width: 100%;
    max-width: $appmaxwidth;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 0 40px;
    > div {
      display: flex;
      align-items: center;
      > a {
        color: #fff;
        padding: 0 15px;
        text-decoration: none;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        > img {
          width: 150px;
        }
      }
      > a:hover {
        // transform: scale(1.2);
        color: $themecolor;
      }
      button {
        color: white !important;
        border-radius: 35px !important;
        background: linear-gradient(291.95deg, #876af6 12.39%, #a587f8 83.66%);
        font-weight: 600 !important;
        font-size: 16px;
        display: inline-block;
        transition: 0.3s;
        margin-right: 0.5em;
        padding: 10px 28px;
        border: none;
        outline: 0;
        > a {
          font-size: 16px;
          color: white;
          text-decoration: none;
          font-weight: 600;
        }
      }
    }
  }
}
.navbar-wrapper.active {
  display: none;
}

@media screen and (max-width: 795px) {
  .navbar-wrapper {
    display: none;
  }
}
