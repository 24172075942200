@import "../../../variables";
.planCard-wrapper {
  width: 350px;
  margin: 40px;
  border-radius: 25px;
  background-color: #100d2f;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;
  > .title-container {
    text-align: center;
    width: 100%;
    height: 80px;
    background-image: url(../../../images/productimg.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    > h3 {
      font-size: 24px;
      margin: 0;
    }
    > h5 {
      margin: 0;
    }
  }
  > div {
    height: 200px;
    text-align: center;
    padding: 20px;
  }
  select {
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: white;
    width: 80%;
    padding: 12px;
    border: 3px solid $themecolor;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
    background-color: #151231;
    border-radius: 10px;
    font-size: 16px;
    outline: none;
  }
  button {
    margin-top: 15px;
    border-radius: 35px !important;
    background: linear-gradient(291.95deg, #876af6 12.39%, #a587f8 83.66%);
    font-weight: 600 !important;
    font-size: 16px;
    padding: 10px 28px;
    border: none;
    outline: 0;
    width: 160px;
    color: #fff;
    > a {
      text-decoration: none;
      color: #fff;
    }
  }
}
@media screen and (max-width: 760px) {
  .planCard-wrapper {
    margin: 10px;
  }
}
