@import "../../variables";
.footer-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  .footer-content {
    max-width: $appmaxwidth;
    width: 70%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    > div {
      height: 200px;
      display: flex;
      flex-direction: column;
      text-align: left;

      > img {
        width: 200px;
      }
      h6 {
        font-size: 16px;
        margin: 0;
        margin-bottom: 10px;
      }
      a {
        color: #fff;
        text-decoration: none;
        line-height: 25px;
        font-size: 16px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .footer-wrapper {
    .footer-content {
      display: flex;
      flex-direction: column;
      > div {
        height: max-content;
        padding: 30px 0;
        width: 80%;
      }
    }
  }
}
