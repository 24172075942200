@import "../../variables";
.about-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  .about-container {
    max-width: $appmaxwidth;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 50px;
    > h3 {
      margin-bottom: 0.5em;
      color: $themecolor;
      font-size: calc(1.3rem + 0.6vw);
      font-weight: 500;
      line-height: 1.2;
    }
    > p {
      font-size: 18px;
      font-weight: 400;
      line-height: 1.5;
      color: #fff;
    }
    > button {
      margin-top: 15px;
      border-radius: 35px !important;
      background: linear-gradient(291.95deg, #876af6 12.39%, #a587f8 83.66%);
      font-weight: 600 !important;
      font-size: 16px;
      padding: 10px 28px;
      border: none;
      outline: 0;
      width: 160px;
      > a {
        text-decoration: none;
        color: #fff;
      }
    }
  }
}
