@import "../../variables";
.home-wrapper {
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  > .home-container {
    max-width: $appmaxwidth;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    > .left {
      width: 45%;
      text-align: left;
      > h1 {
        color: #fff;
        font-size: 4rem;
        font-weight: 500;
        margin: 0;
      }
      > p {
        color: #fff;
        font-weight: 500;
        font-size: 1.75rem;
      }
      > .button-container {
        width: 100%;
        text-align: left;
        > button {
          border-radius: 35px !important;

          margin-right: 20px;
          font-weight: 600 !important;
          font-size: 16px;
          padding: 10px 28px;
          border: none;
          outline: 0;
          width: 160px;
          > a {
            text-decoration: none;
            color: #fff;
          }
          .a2 {
            color: #000;
          }
        }
        .btn1 {
          background: linear-gradient(291.95deg, #876af6 12.39%, #a587f8 83.66%);
        }
        .btn2 {
          background-color: #fff;
        }
      }
    }
    @keyframes updown {
      0% {
        transform: translateY(-50px);
      }

      50% {
        transform: translateY(50px);
      }

      100% {
        transform: translateY(-50px);
      }
    }
    .right {
      width: 45%;
      display: flex;
      justify-content: center;
      align-items: center;
      .image {
        width: 80%;
        animation: updown 3s ease-in-out infinite alternate-reverse both;
      }
    }
  }
}
@media screen and (max-width: 796px) {
  .home-wrapper {
    > .home-container {
      display: flex;
      flex-direction: column;
      > .left {
        width: 90%;
        text-align: center;
        padding: 50px 0;
        > .button-container {
          text-align: center;
          > button {
            margin: 20px;
          }
        }
      }
      .right {
        width: 90%;
      }
    }
  }
}
