@import "../../variables";
.features-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 50px 0;
  > .features-container {
    max-width: $appmaxwidth;
    width: 90%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    > .left {
      width: 50%;
      > h4 {
        margin-bottom: 0.5em;
        color: $themecolor;
        font-weight: 500;
        line-height: 1.2;
        font-size: 1.25rem;
      }
      > h2 {
        margin-top: 0;
        margin-bottom: 0.5rem;
        font-weight: 500;
        line-height: 1.2;
        font-size: calc(1.3rem + 0.6vw);
        color: #fff;
      }
      > p {
        font-size: 18px;
        color: #fff;
      }
      > button {
        margin-top: 15px;
        border-radius: 35px !important;
        background: linear-gradient(291.95deg, #876af6 12.39%, #a587f8 83.66%);
        font-weight: 600 !important;
        font-size: 16px;
        padding: 10px 28px;
        border: none;
        outline: 0;
        width: 160px;
        > a {
          text-decoration: none;
          color: #fff;
        }
      }
    }
    > .right {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}
@media screen and (max-width: 768px) {
  .features-wrapper {
    > .features-container {
      display: flex;
      flex-direction: column;
      > .left {
        width: 90%;
        padding: 80px 0;
      }
      > .right {
        width: 100%;
      }
    }
  }
}
